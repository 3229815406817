<template>
    <div class="application-details" :class="{ 'complete': application && application.step && application.step.status === 'Closed'}">
        <div class="student-curriculum">
            <div class="student-curriculum-marker" :class="[(application ? application.curriculumIdentifier : '')]"></div>

            <div v-if="application" class="student-curriculum-info">
                <span class="curriculum-name fw-bold">{{ application.type }}</span>
                <span class="student-name fw-bold">{{ application.title }}</span>
                <span class="entering-grade-year" v-html="application.blurb"></span>
            </div>

            <div v-if="isNew" class="application-details-info new-app-btn">
                <a :href="newUrl" class="d-flex align-items-center">
                    <div class="application-icon-container">
                        <i class="bi bi-plus"></i>
                    </div>

                    <div class="mx-3">{{ buttonText }}</div>
                </a>
            </div>

            <div v-if="application && application.canEdit" class="flex-fill position-relative">
                <button class="btn btn-delete-application position-absolute bottom-0 end-0" data-bs-toggle="modal" :data-bs-target="`#confirm-delete-${ application.id }`">
                    <i class="bi bi-trash3-fill"></i>
                </button>
            </div>

            <div v-if="application" :id="`confirm-delete-${application.id}`" class="modal" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Delete Application</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p>Are you sure you wish to delete this application?</p>
                        </div>
                        <div class="modal-footer">
                            <form :action="application.deleteRoute" method="POST">
                                <input class="btn btn-danger text-light" type="submit" value="Delete" />
                            </form>

                            <button type="button" class="btn btn-secondary text-light" data-bs-dismiss="modal">Cancel</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="!isNew">
            <div v-if="application.step" class="application-steps text-center">
                <template v-if="application.isCancelled">
                    <span>This application has been closed as per your request</span>
                </template>

                <template v-else-if="!application.step.destinationLink">
                    <span>{{ application.step.name }}</span>
                </template>

                <template v-else>
                    <span> {{ application.step.title ?? 'School Application Requirement' }}</span>
                    <p v-if="application.step.description" class="step-description mb-2"> {{ application.step.description }} </p>
                    <a class="uct-btn-link" :href="application.step.destinationLink">{{ application.step.name }}</a>
                    <countdown-timer
                        v-if="application.applicationWindowExpires !== null"
                        :countdown-date="application.applicationWindowExpires"
                        :is-dashboard="true"
                    >
                    </countdown-timer>
                </template>

                <div v-if="application && application.curriculumIdentifier === 'adult-matric' && hasTrueAction(application) && !application.isCancelled" class="dropdown">
                    <button class="dropdown-toggle no-after" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-three-dots-vertical"></i>&nbsp;<span>Edit</span>
                    </button>

                    <ul class="dropdown-menu">
                        <li v-if="isActionTrue(application, 'editSubjectOrBundle')">
                            <a class="dropdown-item" :href="application.actionEditSubjectOrBundle">Edit subjects or bundle</a>
                        </li>

                        <li v-if="isActionTrue(application, 'changeStartDate')">
                            <a class="dropdown-item" :href="application.actionChangeStartDateUrl">Change start date</a>
                        </li>

                        <li v-if="isActionTrue(application, 'cancelEnrolment')">
                            <button
                                type="button"
                                class="dropdown-item"
                                data-bs-toggle="modal"
                                :data-bs-target="`#amCancelEnrolment-${application.id}`"
                            >Cancel enrolment</button>
                        </li>
                    </ul>
                </div>
            </div>

            <div v-else-if="application.step.status !== 'Closed'" class="application-steps text-center">
                <span>Error. Please contact us for help.</span>
            </div>

            <div v-if="application.isEligibleForScholarship" class="scholarship-steps p-0 pt-3">
                <span class="scholarship-steps-name fw-bold px-3">Bursary Application Progress</span>
                <span class="step-indicator mx-3" :class="[('pos-' + application.scholarshipInfo.position.replace(/ /gm, '-'))]">
                    <div class="step-indicator-dot main"></div>

                    <div class="step-indicator-dot main"></div>

                    <div class="step-indicator-dot main"></div>

                    <div class="step-indicator-dot main"></div>
                </span>

                <span class="scholarship-steps-next px-3 mb-2">Next Step, <span class="fw-bold">{{ application.scholarshipInfo.status }}</span>: {{ application.scholarshipInfo.position }}</span>

                <a :href="application.scholarshipLink" class="px-3 mb-0 mt-1 next-child-step">{{ application.scholarshipInfo.status === 'Applicant' ? application.scholarshipInfo.subStatus : application.scholarshipInfo.status }}</a>
            </div>

            <div v-else-if="application.canCheckScholarshipEligibility" class="scholarship-steps justify-center p-2">
                <a :href="eligibilityUrl" class="uct-btn-link dark">Check eligibility for LLSB</a>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "ApplicationsDetails",
    props: {
        application: {
            type: Object,
            default: null,
            required: false,
        },
        isNew: {
            type: Boolean,
            default: false,
        },
        newUrl: {
            type: String,
            required: false,
        },
        eligibilityUrl: {
            type: String,
            required: false,
            default: '',
        },
        buttonText: {
            type: String,
            required: false,
            default: 'Start a New Application',
        }
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        hasAction(application, action) {
            return application.hasOwnProperty('actions') && application.actions.hasOwnProperty(action);
        },

        isActionTrue(application, action) {
            if (!this.hasAction(application, action)) {
                return false;
            }

            return Boolean(application.actions[action]);
        },

        hasTrueAction(application) {
            if (!application.hasOwnProperty('actions') && application.actions) {
                return false;
            }

            return Object.values(application.actions).includes(true);
        },
    },
}
</script>

<style lang="scss" scoped>

@import 'resources/sass/abstract/colours.scss';

.application-details {
    display: flex;
    flex-basis: 100%;
    min-height: 130px;
    margin-bottom: 24px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    &.new {
        flex-basis: 100%;
    }

    &-info {
        display: flex;
        align-items: center;
        padding: 20px;

        .application-icon-container {
            border: 3px solid $pastel-blue;
            font-size: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            border-radius: 50%;
            height: 40px;
            color: $pastel-blue;
        }

        a {
            font-size: 20px;
            color: $pastel-blue;
            font-weight: 600;
            margin-left: 26px;
            text-decoration: none;
        }
    }
}

.student-curriculum,
.application-steps,
.scholarship-steps {
    flex-basis: calc((100% / 3) - 24px);
    flex-grow: 1;
    flex-shrink: 1;
    background-color: $white;
    color: $pastel-blue;
}

.application-steps,
.scholarship-steps {
    margin-left: 12px;

    @media screen and (max-width: 768px) {
        margin-left: 0;
        margin-top: 0.5rem;
    }
}

.scholarship-steps {
    background-color: $light-blue-F0;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span,
    a {
        font-size: 13px;
        color: $pastel-blue;
        margin-bottom: 12px;
    }

    & > span {
        display: block;
        margin: 0;
    }

    .next-child-step {
        background-color: $uctDarkBlue;
        height: 50px;
        color: $white;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        border-bottom-right-radius: 32px;
        display: flex;
        align-items: center;
    }

    .step-indicator {
        background-color: $white;
        height: 10px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 0;
        margin: 0.5rem 0;
        overflow: hidden;
        position: relative;

        &::after,
        & {
            border-radius: 20px;
        }

        &::after {
            content: "";
            display: block;
            height: 100%;
            width: 80%;
            background-color: $uctDarkBlue;
            position: absolute;
            top: 0;
            left: 0;
        }

        &-dot {
            border-radius: 50%;
            background-color: $white;
            z-index: 10;

            &.main {
                width: 7px;
                height: 7px;
            }

            &.sub {
                height: 4px;
                width: 4px;
            }
        }

        &.pos-1-of-4 {
            &::after {
                width: 24%;
            }

            .step-indicator-dot:nth-child(n+2) {
                background-color: $uctDarkBlue;
            }
        }

        &.pos-2-of-4 {
            &::after {
                width: 44%;
            }

            .step-indicator-dot:nth-child(n+3) {
                background-color: $uctDarkBlue;
            }
        }

        &.pos-3-of-4 {
            &::after {
                width: 64%;
            }

            .step-indicator-dot:nth-child(n+4) {
                background-color: $uctDarkBlue;
            }
        }

        &.pos-4-of-4 {
            &::after {
                width: 100%;
            }

            .step-indicator-dot:nth-child(n+5) {
                background-color: $uctDarkBlue;
            }
        }
    }

    .uct-btn-link.dark {
        align-self: center;
        margin-top: 0.5rem;
        color: $white;
    }
}

.application-steps {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media screen and (max-width: 768px) {
        padding: 1rem;
    }

    span {
        font-size: 13px;
        color: $pastel-blue;
        font-weight: 600;
        margin-bottom: 0.5em;
    }
}

.student-curriculum:first-child:nth-last-child(2),
.application-steps:last-child:nth-last-child(1) {
    flex-basis: calc((100% / 3) - 8px);
    flex-grow: 0;
}

.application-steps:last-child:nth-last-child(1) {
    flex-grow: 2;
}

.application-steps:last-child:nth-last-child(1),
.scholarship-steps,
.student-curriculum:last-child,
.application-details.new {
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;

    @media screen and (max-width: 768px) {
        border-top-right-radius: 0;
        border-bottom-left-radius: 32px;
    }
}

.student-curriculum {
    display: flex;

    @media screen and (max-width: 768px) {
        position: relative;
    }

    &-marker {
        width: 20px;
        background-color: $uctDarkBlue;
        flex-shrink: 0;
        flex-grow: 0;

        &.caps {
            background-color: map-get($primary-themes, "caps");
        }

        &.caie {
            background-color: map-get($primary-themes, "caie");
        }

        &.adult-matric {
            background-color: map-get($primary-themes, "adult-matric");
        }

        @media screen and (max-width: 768px) {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(-100%);
            height: 100%;
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        padding: 20px;
        justify-content: center;
    }
}

.student-name,
.school-name {
    font-size: 13px;
}

.entering-grade-year {
    font-size: 16px;
}

.curriculum-name {
    font-size: rem-calc(18);
}

.new-app-btn {
    @media screen and (max-width: 768px) {
        background-color: $white;
        width: 100%;
        border-bottom-right-radius: 32px;
    }
}

.step-description {
    font-size: 12px;
    color: $black;
    padding: 0 5px;
}

.no-after::after {
    display: none;
    visibility: hidden;
}

::v-deep .dropdown {
    position: absolute;
    top: 0;
    right: 0;
    color: $pastel-blue;

    &-toggle {
        height: 40px;
        padding-right: 1rem;
        border-radius: 32px;
    }

    &-menu {
        border-color: $pastel-blue;

        --shadow-color: 217.89deg 57.58% 32.35%; // $pastel-blue

        box-shadow: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
            0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
            2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
            5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);
    }

    &-item {
        color: $pastel-blue;
        font-size: 14px;

        &:hover {
            background-color: $pastel-light-blue;
            color: $white;
        }
    }
}

</style>
