<template>
    <div class="step-one-controller">
        <div class="application-step-px">
            <application-steps-progress
                :is-adult-matric-application="isApplyingFor('adult-matric')"
                :current-step-name="isApplyingFor('adult-matric') ? 'Learner Info' : 'Submit Learner Info'"
            ></application-steps-progress>
        </div>

        <!-- Don't allow curriculum changes if Adult Matric is editing -->
        <div v-show="!isAmEditing" class="application-step-w mx-auto px-3 px-lg-0">
            <div class="container p-0">
                <div class="row mb-3">
                    <div
                        v-for="(option, key) in offeredCurriculumOptions()"
                        :key="key"
                        class="col-12 col-lg-6"
                    >
                        <vi-selectable-card
                            :class="`theme-${option.identifier}`"
                            :key="key"
                            :label="option.name"
                            :trueValue="option.id"
                            v-model="chosenCurriculum"
                            :isChecked="chosenCurriculum === option.id"
                            :disabled="(!canAddAdultMatricApplication && option.identifier === 'adult-matric') || isEditing || amReenrolmentId !== null"
                            class="curriculum-card"
                            :shouldBlockSelectedClick="true"
                        >
                            <template v-if="option.description" v-slot:content>
                                <p class="text-center curriculum-description" v-html="option.description"></p>
                            </template>
                        </vi-selectable-card>
                    </div>
                </div>
            </div>
        </div>

        <guardian-learner-app-step-one-form
            v-if="isApplyingFor('caps')"
            :user-id="userId"
            :countries="countries"
            :languages="languages"
            :application-model="applicationModel"
            :grades="grades"
            :caps-dates="capsDates"
            :application-id="applicationId"
            :closed-grade-text="closedGradeText"
            :submit-route="submitRoute"
            :back-url="backUrl"
            :active-step="activeStep"
            :get-application-route="getApplicationRoute"
            :next-button-text="nextButtonText"
            :preferred-curriculum="preferredCurriculum"
            :user-details="userDetails"
            :caps-age-bracket="capsAgeBracket"
            :can-add-adult-matric-application="canAddAdultMatricApplication"
            :lead-form-urls="leadFormUrls"
            :duplicate-route="duplicateRoute"
            :english-grade-options="englishGradeOptions"
            :math-grade-options="mathGradeOptions"
            :dial-codes="dialCodes"
            :capture-guardian-details="captureGuardianDetails"
            :enrollment-rules="enrollmentRules"
            :chosen-curriculum="chosenCurriculum"
            :offered-curriculum-options="offeredCurriculumOptions()"
            @curriculum-select="onCurriculumSelect"
        ></guardian-learner-app-step-one-form>

        <adult-matric-learner-info
            v-if="isApplyingFor('adult-matric') && this.adultMatricDates.length !== 0"
            :capture-guardian-details="captureGuardianDetails"
            :application-id="applicationId"
            :user="userDetails"
            :dial-codes="dialCodes"
            :back-url="backUrl"
            :next-button-text="nextButtonText"
            :start-dates="adultMatricDates"
            :submit-url="adultMatricSubmit"
            :applied-curriculum="chosenCurriculum"
            :guardian-contact="guardianContact"
            :enrollment-rules="enrollmentRules['adult-matric']"
            :application="application"
            :am-reenrolment-id="amReenrolmentId"
            :offered-curriculum-options="offeredCurriculumOptions()"
            @curriculum-select="onCurriculumSelect"
        ></adult-matric-learner-info>

        <div v-if="isApplyingFor('adult-matric') && this.adultMatricDates.length == 0" class="application-step-w mx-auto px-3 px-lg-0">
            <div ref="initialScrollTarget"></div>
            <div class="application-step-3">
                <div class="lead-wrapper">
                    <p class="mb-3">Our Adult Matric Applications open soon!<br>Be the first to find out when they do:</p>

                    <a
                        class="anchor-btn new-btn new-btn-dark mt-2"
                        @click="redirectToLeadForm"
                    >Capture my details</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isEmpty } from 'lodash';
import query_params from '../../mixins/query_params';

export default {
    name: 'StepOneController',
    mixins: [
        query_params,
    ],
    props: [
        'userId',
        'languages',
        'backUrl',
        'countries',
        'capsDates',
        'grades',
        'applicationModel',
        'applicationId',
        'closedGradeText',
        'activeStep',
        'submitRoute',
        'nextButtonText',
        'getApplicationRoute',
        'preferredCurriculum',
        'adultMatricDates',
        'userDetails',
        'capsAgeBracket',
        'canAddAdultMatricApplication',
        'leadFormUrls',
        'duplicateRoute',
        'englishGradeOptions',
        'mathGradeOptions',
        'dialCodes',
        'captureGuardianDetails',
        'enrollmentRules',
        'adultMatricSubmit',
        'guardianContact',
        'application',
        'amReenrolmentId'
    ],
    data() {
        return {
            chosenCurriculum: this.getCapsId(),
            isAmEditing: false,
        };
    },
    mounted() {
        if (this.preferredCurriculum) {
            let preferred = parseInt(this.preferredCurriculum);
            let adultMatric = this.applicationModel.offeredCuriculum.find(curriculum => 'adult-matric' === curriculum.identifier);

            if ((preferred === adultMatric.id && this.canAddAdultMatricApplication) || preferred !== adultMatric.id) {
                this.chosenCurriculum =  preferred;
            }
        }

        this.isAmEditing = this.getQueryParamBoolean('changeStartDate', false);
    },
    computed: {
        isEditing() {
            return !isEmpty(this.applicationId);
        },
    },
    methods: {
        offeredCurriculumOptions() {
            return this.applicationModel.offeredCuriculum.filter((item) => item.identifier != 'caie').map((item) => {
                if (! this.canAddAdultMatricApplication && item.identifier == 'adult-matric') {
                    return {
                        ...item,
                        description: 'Your application is in progress' + (item.description ? `<br>${item.description}` : ''),
                    };
                }

                return item;
            });
        },
        isApplyingFor(target) {
            let targetCurriculum = this.applicationModel.offeredCuriculum.find(curriculum => target === curriculum.identifier);

            if (targetCurriculum != null) {
                return this.chosenCurriculum == targetCurriculum.id;
            }

            return false;
        },
        getCapsId() {
            return this
                .applicationModel
                .offeredCuriculum
                .find(curriculum => 'caps' === curriculum.identifier)
                .id;
        },
        onCurriculumSelect(event) {
            this.chosenCurriculum = event.chosenCurriculum;
        },
        redirectToLeadForm() {
            let decodedUrls = JSON.parse(this.leadFormUrls);
            let leadFormUrl = decodedUrls.caps;

            if (this.isApplyingFor('caie')) {
                leadFormUrl = decodedUrls.caie;
            }

            if (this.isApplyingFor('adult-matric')) {
                leadFormUrl = decodedUrls.adultMatric;
            }

            if (leadFormUrl != null) {
                window.open(leadFormUrl);
            }
        },
    },
}
</script>

<style lang="scss" scope>
.lead-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;

    p {
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
    }
}

.anchor-btn {
    padding-left: 16px;
    padding-right: 16px;
    width: 50% !important;
}
</style>
