<template>
    <div>
        <form @submit.prevent="validateAndConfirm" method="POST" class="text-start">
            <h4 class="text-center text-dark mb-4">Subject Selection for Adult Matric Preparation</h4>

            <slot name="bodyCopy">
                <p>Please select the subjects you want to take when you join UCT online High School.</p>

                <p>When making your subject selections, it is important that you understand the requirements needed to obtain your Amended Senior Certificate. You can read more about this <a href="https://www.education.gov.za/Curriculum/SeniorCertificate.aspx" target="_blank">here</a>.</p>

                <p>Lets begin by selecting the bundle of subjects you would like to take.</p>

                <p>Which subject bundle would you like to opt for?</p>
            </slot>

            <div class="bundle-container">
                <div v-for="(bundleInfo, key) in orderedBundleInfo" :key="key" :class="['bundle', bundleInfo.tag, {'selected': isBundleSelected(bundleInfo.type, bundleInfo.bundle), 'disabled': disableBundles}]">
                    <div class="top">
                        <span class="title">{{ bundleInfo.title }}</span>
                        <span class="subtitle">{{ bundleInfo.subtitle }}</span>
                    </div>

                    <div v-if="bundleInfo.tag" class="tag">{{ bundleInfo.tag }}</div>

                    <hr>

                    <div class="bottom">
                        <span class="price">R{{ bundleInfo.price / 100.00 }}/month</span>
                        <span class="description">{{ bundleInfo.description }}</span>

                        <button @click.prevent="() => selectBundle(bundleInfo.type, bundleInfo.bundle)" type="button" :for="`bundleInfo-${key}`">
                            <span v-if="isBundleSelected(bundleInfo.type, bundleInfo.bundle)" class="icon"><i class="bi bi-check-lg"></i></span>
                            <span v-else class="text">Select</span>
                        </button>
                    </div>
                </div>
            </div>

            <section v-if="isASC">

                <p>The following subjects are mandatory (compulsory):</p>

                <ul class="list-unstyled">
                    <li v-for="(subjectName, subjectCode) in subjects.preSelectedSubjects" :key="`pre-selected-${subjectCode}`" class="fw-bold">
                        <i class="bi bi-check-circle"></i>
                        {{subjectName}}
                    </li>
                </ul>

                <div class="form-floating mb-3 mt-5 mt-sm-0">
                    <select class="form-select new-input" name="additional_language" v-model="selectedFAL">
                        <option :value="null" disabled="disabled" selected="selected">Select...</option>
                        <option v-for="(subjectName, subjectCode) in subjects.languageSubjects" :value="subjectCode" :key="`langage-${subjectCode}`">{{subjectName}}</option>
                    </select>

                    <label class="long-label-select" for="additional_language">Please select a First Additional Language (FAL)</label>

                    <span v-if="showErrors && !selectedFAL" class="text-danger">You must select a First Additional Language</span>
                </div>

                <div>
                    <p class="mb-1">Please select only <u><strong>four</strong></u> subjects.</p>
                    <ul class="list-unstyled">
                        <li v-for="(subjectName, subjectCode) in subjects.extraSubjects" :key="`extra-${subjectCode}`">

                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    :id="`select-subject-${subjectCode}`"
                                    :value="subjectCode"
                                    v-model="selectedSubjects"
                                    :disabled="disableSubjects || (!isSubjectSelected(subjectCode) && hasSelectedMaxSubjects())"
                                >

                                <label class="form-check-label" :for="`select-subject-${subjectCode}`">
                                    {{subjectName}}
                                </label>
                            </div>

                        </li>
                    </ul>
                </div>

            </section>

            <section v-else-if="isReduced">
                <p>Please select the subjects that you would like to take</p>

                <ul class="list-unstyled">
                    <li v-for="(subjectName, subjectCode) in subjects.allSubjects" :key="`extra-${subjectCode}`">

                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                :id="`select-subject-${subjectCode}`"
                                :value="subjectCode"
                                v-model="selectedSubjects"
                                :disabled="disableSubjects || (!isSubjectSelected(subjectCode) && hasSelectedMaxSubjects())"
                            >

                            <label class="form-check-label" :for="`select-subject-${subjectCode}`">
                                {{ subjectName }}
                            </label>
                        </div>
                    </li>
                </ul>

            </section>

            <span v-if="showErrors && errorMessage != null" class="text-danger">{{ errorMessage }}</span>

            <vi-error-flash
                v-if="unexpectedError"
                errorMessage="We are unable to save your subject selection right now. Please try again later."
            ></vi-error-flash>

            <div class="alert alert-info"
                v-if="subjectBundleType === 'reduced' && selectedReducedBundle === null"
            >Please select your desired Bundle above.</div>

            <slot name="disclaimer"></slot>

            <div class="d-flex justify-content-between flex-wrap w-100 mt-5">
                <slot name="leftBtn">
                    <a :href="backButtonUrl" class="new-btn new-btn-dark new-btn-sm mt-2 me-0 me-sm-2">Back</a>
                </slot>

                <slot name="rightBtn" v-bind:validate="validate">
                    <vi-button-spinner
                        :buttonClass="{
                            'new-btn': true,
                            'new-btn-dark': true,
                            'new-btn-sm': true,
                            'mt-2': true,
                        }"
                        buttonText="Confirm"
                        :loading="loading"
                        :disabled="subjectBundleType === 'reduced' && selectedReducedBundle === null"
                    ></vi-button-spinner>
                </slot>
            </div>
        </form>

        <div class="modal fade" id="confirmSubjectsModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="confirmSubjectsModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content">
                    <div class="modal-body text-center p-4">
                        <p>Thank you for making your subject selections. Please confirm your selection of the following subjects:</p>
                        <ul class="list-unstyled">
                            <li v-for="(subjectName, index) in allSelectedSubjects" :key="`selected-confirm-${index}`">
                                {{subjectName}}
                            </li>
                        </ul>
                    </div>
                    <div class="modal-footer p-3 mb-2 justify-content-center flex-column">
                        <button type="button" class="btn-outline-uct rounded-pill px-5 py-2 text-decoration-none mt-2 me-sm-2 me-0 ms-0" data-bs-dismiss="modal">Go back to subject selection</button>

                        <button
                            type="button"
                            class="btn-outline-uct-dark rounded-pill px-5 py-2 text-decoration-none mt-2 mx-0"
                            @click="submit"
                            :disabled="loading"
                        >
                            <template v-if="loading">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                               </div>
                            </template>

                            <template v-else>Confirm subject selection</template>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import helperMixin from "../../mixins/helper_mixin";
export default {
    name: 'AdultMatricSubjectSelection',
    props: {
        submitUrl: {
            type: String,
            default: ''
        },
        backButtonUrl: {
            type: String,
            default: ''
        },
        subjects: {
            type: Object,
            required: true
        },
        selectionInfo: {
            type: Object,
            required: true
        },
        bundleInfo: {
            type: Array,
            required: true,
        },
        disableSubjects: {
            type: Boolean,
            default: false,
        },
        disableBundles: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        selectedFAL: null,
        selectedSubjects: [],
        showErrors : false,
        loading : false,
        unexpectedError : false,
        subjectBundleType: 'asc',
        selectedReducedBundle: 1,
        errorMessage: null,
        minSubjects: null,
        maxSubjects: null,
    }),
    mixins: [helperMixin],
    mounted() {
        this.setSelectedBundle();

        this.$nextTick(() => {
            this.selectedFAL = this.selectionInfo.selectedLanguage;
            this.selectedSubjects = this.selectionInfo.selectedSubjects ?? [];

            this.$emit('subject-selection-fal', this.selectionInfo.selectedLanguage);
            this.$emit('subject-selection-subjects', this.selectionInfo.selectedSubjects ?? []);

            if (this.subjectBundleType === 'asc') {
                this.selectedSubjects = this.selectedSubjects.filter(subject => subject !== 'ENG');
            }
        });
    },
    computed: {

        isASC() {
            return this.subjectBundleType == 'asc';
        },

        isReduced() {
            return this.subjectBundleType == 'reduced';
        },

        /**
         * Check if form is valid
         *
         * @return {boolean}
         */
        formIsValid() {
            if (this.isASC) {
                if (this.selectedFAL != null && this.selectedSubjects.length == 4) {
                    return true;
                }

                if (this.selectedSubjects.length < this.minSubjects || this.selectedSubjects.length < this.maxSubjects) {
                    this.errorMessage = 'Please select four additional subjects.';
                    return false;
                }

                if (this.selectedSubjects.length > this.maxSubjects) {
                    this.errorMessage = `Please a maximum of ${this.maxSubjects} subjects.`;
                    return false;
                }
            }

            if (this.isReduced) {
                if (this.selectedReducedBundle == 1) {
                    if (this.selectedSubjects.length == 1) {
                        return true;
                    }

                    this.errorMessage = 'You can take a maximum of 1 subject in bundle 1. If you want to take more subjects, please select a new bundle.';

                    return false;
                }

                if (this.selectedReducedBundle == 2) {
                    if (this.selectedSubjects.length >= this.minSubjects && this.selectedSubjects.length <= this.maxSubjects) {
                        return true;
                    }

                    this.errorMessage = `You can take between a minimum of ${this.minSubjects} and a maximum of ${this.maxSubjects} subjects in bundle 2. If you want to take more subjects, please select a new bundle.`;

                    return false;
                }
            }

            return false;
        },

        /**
         * Get all selected subjects
         *
         * @return {string[]}
         */
        allSelectedSubjects() {
            var allSelectedSubjects = [];

            if (this.isASC) {
                allSelectedSubjects.push(...Object.values(this.subjects.preSelectedSubjects));
                allSelectedSubjects.push(this.subjects.languageSubjects[this.selectedFAL]);
                allSelectedSubjects.push(...this.selectedSubjects.map(subjectCode => this.subjects.extraSubjects[subjectCode]));
            } else {
                allSelectedSubjects.push(...this.selectedSubjects.map(subjectCode => this.subjects.allSubjects[subjectCode]));
            }

            return allSelectedSubjects;
        },
        hasSelectedAMathSubject() {
            return this.selectedSubjects.includes('MAT') || this.selectedSubjects.includes('MAL');
        },
        orderedBundleInfo() {
            return this.bundleInfo.sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                }

                if (a.order > b.order) {
                    return 1;
                }

                return 0;
            })
        },
    },
    methods: {

        isBundleSelected(type, bundle) {
            let $isSelected = this.subjectBundleType === type && this.selectedReducedBundle === bundle;

            if ($isSelected) {
                this.$emit('subject-selection-bundle', { bundle, type });
            }

            return $isSelected;
        },

        selectBundle(type, bundle) {
            this.subjectBundleType = type;
            this.selectedReducedBundle = bundle;

            this.$emit('subject-selection-bundle', { bundle, type });
        },

        validate() {
            this.errorMessage = null;

            if (!this.formIsValid) {
                this.showErrors = true;

                return false;
            }

            return true;
        },

        /**
         * Validate form and show confirmation modal
         *
         * @return {void}
         */
        validateAndConfirm() {
            let isNotValid = !this.validate();

            if (isNotValid) {
                return;
            }

            var confirmSubjectsModal = new bootstrap.Modal(document.getElementById('confirmSubjectsModal'), {
                keyboard: false
            });

            confirmSubjectsModal.show();
        },

        setSelectedBundle() {
            if (this.selectionInfo.selectedBundle != null) {
                if (this.selectionInfo.selectedBundle == 'Full ASC') {
                    this.subjectBundleType = 'asc';
                    this.selectedReducedBundle = null;
                } else {
                    this.subjectBundleType = 'reduced';
                    this.selectedReducedBundle =  parseInt(this.selectionInfo.selectedBundle.replace('Bundle ', ''));
                }

            } else {
                this.subjectBundleType = 'asc',
                this.selectedReducedBundle = null;
            }

            this.setMaxAndMinSubjectsForBundle();
        },

        /**
         * Submit subject selection form
         *
         * @return {void}
         */
        async submit() {
            this.loading = true;
            this.unexpectedError = false;

            const subjectData = {
                subjects: this.selectedSubjects,
            };

            if (this.isASC) {
                subjectData.bundle_type = 'Full ASC';
                subjectData.additional_language = this.selectedFAL;
                subjectData.subjects.push(...Object.keys(this.subjects.preSelectedSubjects));

            } else {
                subjectData.bundle_type = `Bundle ${this.selectedReducedBundle}`;
            }

            try {
                var response = await axios.post(this.submitUrl, subjectData);

                if (response.status == 200) {
                    if (response.data) {
                        this.triggerGa();
                        window.location.href = response.data;
                    }
                } else {
                    this.unexpectedError = true;
                }

            } catch(error) {
                this.unexpectedError = true;
            }
        },
        triggerGa() {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                'event': 'preliminarySubjectSelectionAdultMatric',
                'userid': window.valenture.userId,
            });
        },
        clearChosenSubjects() {
            this.selectedFAL = null;
            this.selectedSubjects = [];

            this.$emit('subject-selection-fal', this.selectedFAL);
            this.$emit('subject-selection-subjects', this.selectedSubjects);
        },
        setMaxAndMinSubjectsForBundle() {
            if (this.subjectBundleType === 'asc') {
                this.minSubjects = 4;
                this.maxSubjects = 4;

                return;
            }

            if (this.subjectBundleType === 'reduced') {
                if ([1, '1'].includes(this.selectedReducedBundle)) {
                    this.minSubjects = 1;
                    this.maxSubjects = 1;

                    return;
                }

                if ([2, '2'].includes(this.selectedReducedBundle)) {
                    this.minSubjects = 2;
                    this.maxSubjects = 3;

                    return;
                }
            }

            this.minSubjects = null;
            this.maxSubjects = null;
        },
        hasSelectedMaxSubjects() {
            if (this.maxSubjects === null) {
                return false;
            }

            // Excluding compulsory subjects and FAL
            let numberOfSelectedSubjects = this.selectedSubjects.length;

            if (numberOfSelectedSubjects >= this.maxSubjects) {
                return true;
            }

            return false;
        },
        isSubjectSelected(subjectCode) {
            return Array.isArray(this.selectedSubjects) && this.selectedSubjects.includes(subjectCode);
        },
        resetErrors() {
            this.showErrors = false;
            this.errorMessage = null;
        },
        onBundleChange() {
            this.clearChosenSubjects();

            this.setMaxAndMinSubjectsForBundle();
            this.resetErrors();
        },
    },
    watch: {
        selectedReducedBundle() {
            this.onBundleChange();
        },
        subjectBundleType() {
            this.onBundleChange();
        },
        selectedFAL(val) {
            this.$emit('subject-selection-fal', val);
        },
        selectedSubjects(val) {
            this.$emit('subject-selection-subjects', val);
        },
    }
}
</script>

<style lang="scss" scoped>
@import "../../../sass/abstract/colours.scss";

.bundle-container {
    padding: 2rem 0;

    display: grid;
    grid-row-gap: 1rem;

    @media screen and (min-width: 425px) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
    }

    @media screen and (min-width: 960px) {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 1rem;
    }
}

.bundle {
    $light: $card-light-blue;
    $dark: darken($card-dark-blue, 10%);

    padding: 1rem;
    border-radius: 20px;
    position: relative;
    transition-property: background-color color transform;
    transition-duration: 350ms;
    transition-timing-function: ease;
    background-color: $card-light-blue;
    color: $dark;

    @media screen and (min-width: 425px) {
        display: grid;
        grid-template-rows: 1fr auto auto;
    }

    box-shadow: 0.3px 0.5px 0.7px hsl(0deg 0% 59% / 0.36),
        0.8px 1.6px 2px -0.8px hsl(0deg 0% 59% / 0.36),
        2.1px 4.1px 5.2px -1.7px hsl(0deg 0% 59% / 0.36),
        5px 10px 12.6px -2.5px hsl(0deg 0% 59% / 0.36);

    .tag {
        position: absolute;
        top: 12px;
        left: -18px;
        font-size: 12px;
        padding: 0.25em 0.5rem;
        background-color: $white;
        border-radius: 4px;
        color: $black;
        text-transform: capitalize;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.3);
    }

    .title {
        margin-bottom: 0.75rem;
    }

    .subtitle {
        font-weight: 600;
        font-size: 20px;
    }

    span {
        display: block;
        text-align: center;
    }

    hr {
        height: 2px;
        border: none;
        outline: none;
        color: $dark;
        opacity: 0.4;
        border-radius: 10px;
    }

    .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0.5rem;

        > span {
            margin-bottom: 0.5rem;
        }
    }

    button {
        user-select: none;
        padding: 0.25rem 2rem;
        border-radius: 50px;
        border: 2px solid $dark;
        color: $light;
        background-color: $dark;
        text-transform: uppercase;
        margin-top: 1rem;
        transition: all 250ms ease;

        span {
            margin: 0;
            display: block;
        }
    }

    &.selected {
        background-color: $dark;
        color: $light;

        hr {
            color: $light;
        }

        @media screen and (min-width: 425px) {
            transform: scale(1.075);
        }

        button {
            border-color: $light;
        }
    }

    &.disabled {
        background-color: grayscale($dark);
        color: grayscale($color: $light);
        transform: none !important;
        opacity: 0.7;

        hr {
            color: grayscale($dark);
        }


        button {
            border-color: grayscale($dark);
            color: grayscale($light);
            background-color: grayscale($dark);
            pointer-events: none;

            &:hover {
                cursor: not-allowed
            }
        }
    }

    &:not(.selected) {
        &.popular {
            .tag {
                background-color: $dark;
                color: $light;
            }
        }
    }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.spinner-border {
    height: 1rem;
    width: 1rem;
    border-width: 0.1rem;
}
</style>
